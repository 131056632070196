import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import {
  AcademicCapIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

const actions = [
  {
    title: "Build a new Model",
    href: "#",
    icon: ClockIcon,
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
    description: "Create a new model from scratch.",
  },
  {
    title: "Load an existing Model",
    href: "#",
    icon: CheckBadgeIcon,
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
    description: "Load and modify an existing model.",
  },
  {
    title: "Build a new Workflow",
    href: "#",
    icon: UsersIcon,
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
    description: "Create a new workflow for your project.",
  },
  {
    title: "Load an Existing Workflow",
    href: "#",
    icon: BanknotesIcon,
    iconForeground: "text-yellow-700",
    iconBackground: "bg-yellow-50",
    description: "Load and edit a pre-existing workflow.",
  },
  {
    title: "Build a new Application",
    href: "#",
    icon: ReceiptRefundIcon,
    iconForeground: "text-rose-700",
    iconBackground: "bg-rose-50",
    description: "Start building a new application.",
  },
  {
    title: "Load an Existing Application",
    href: "#",
    icon: AcademicCapIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
    description: "Load and continue working on an application.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function NewCanvasModal({ open, setOpen }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-3xl h-[80vh] flex flex-col">
                {/* Close button */}
                <button
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
                  onClick={() => setOpen("null")}
                >
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Header */}
                <div className="p-6">
                  <h2 className="text-2xl font-semibold text-gray-900">
                    What do you want to build next?
                  </h2>
                </div>

                {/* Action Panel */}
                <div className="p-6 pt-0 grid grid-cols-1 sm:grid-cols-2 gap-4 flex-grow overflow-y-auto">
                  {actions.map((action) => (
                    <div
                      key={action.title}
                      className="relative p-4 bg-white shadow-md rounded-lg cursor-pointer hover:shadow-lg border border-gray-200 text-sm transition-all duration-200 hover:bg-gray-50"
                    >
                      <div className="flex items-center">
                        <span
                          className={classNames(
                            action.iconBackground,
                            action.iconForeground,
                            "inline-flex rounded-lg p-3"
                          )}
                        >
                          <action.icon aria-hidden="true" className="h-6 w-6" />
                        </span>
                        <h3 className="ml-3 text-lg font-semibold text-gray-900">
                          <a href={action.href} className="focus:outline-none">
                            {action.title}
                          </a>
                        </h3>
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        {action.description}
                      </p>
                    </div>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default NewCanvasModal;
