// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import WorkflowCanvas from "./WorkflowCanvas";
import SettingsPage from "./SettingsPage"; // Import the SettingsPage component

function App() {
  return (
    <Router>
      {/* Main content area where the different pages will render */}
      <div>
        <Routes>
          <Route path="/" element={<WorkflowCanvas />} />
          <Route path="/settings" element={<SettingsPage />} />{" "}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
