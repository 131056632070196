import React, { useState } from "react";
import { Handle, Position } from "@xyflow/react";
import Dropdown from "./FormUtils/Dropdown";
import ToggleSwitch from "./FormUtils/ToggleSwitch";

function VariableNode({ data }) {
  const [value, setValue] = useState(data?.value || "");

  const handleSliderChange = (event) => {
    setValue(parseInt(event.target.value, 10));
    if (data.onChange) {
      data.onChange(event.target.value);
    }
  };

  const handleDropdownChange = (selectedOption) => {
    setValue(selectedOption);
    if (data.onChange) {
      data.onChange(selectedOption);
    }
  };

  const handleToggleChange = (newValue) => {
    setValue(newValue);
    if (data.onChange) {
      data.onChange(newValue);
    }
  };

  const handleIntegerChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setValue(newValue);
    if (data.onChange) {
      data.onChange(newValue);
    }
  };

  const handleFloatChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setValue(newValue);
    if (data.onChange) {
      data.onChange(newValue);
    }
  };

  const handleTextChange = (event) => {
    setValue(event.target.value);
    if (data.onChange) {
      data.onChange(event.target.value);
    }
  };

  const handleLoadFromPromptLibrary = () => {
    console.log("Load from Prompt Library clicked");
    // Add your logic for loading from the prompt library here
  };

  const leftColumnWidth = data?.leftColumnPercentage || 50;
  const rightColumnWidth = 100 - leftColumnWidth;

  return (
    <div
      className="rounded-lg bg-yellow-100 border border-gray-300 shadow-sm p-4 flex"
      style={{ width: data?.unitWidth }}
    >
      {/* Left Section (Label) */}
      <div
        className="flex flex-col justify-start"
        style={{ width: `${leftColumnWidth}%` }}
      >
        <span className="text-gray-700 font-semibold">
          {data?.label || "Variable"}
        </span>
      </div>

      {/* Right Section (Input Form) */}
      <div
        className="flex-1 flex items-center justify-end px-2"
        style={{ width: `${rightColumnWidth}%` }}
      >
        {data?.formType === "toggle" ? (
          <ToggleSwitch enabled={value} setEnabled={handleToggleChange} />
        ) : data?.formType === "slider" ? (
          <input
            type="range"
            min={data?.min || 0}
            max={data?.max || 100}
            value={value}
            onChange={handleSliderChange}
            className="slider-input appearance-none w-full h-2 bg-blue-200 rounded-full"
          />
        ) : data?.formType === "dropdown" ? (
          <Dropdown
            options={data?.options || []}
            selected={value}
            setSelected={handleDropdownChange}
            width={data?.menuWidth}
          />
        ) : data?.formType === "integer" ? (
          <input
            type="number"
            value={value}
            onChange={handleIntegerChange}
            className="appearance-none w-full h-8 px-2 rounded-md bg-yellow-50 border border-yellow-700 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-200"
          />
        ) : data?.formType === "float" ? (
          <input
            type="number"
            step="any"
            value={value}
            onChange={handleFloatChange}
            className="appearance-none w-full h-8 px-2 rounded-md bg-yellow-50 border border-yellow-700 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-200"
          />
        ) : data?.formType === "textBox" ? (
          <div className="relative w-full">
            <label htmlFor="textBox" className="sr-only">
              Text Input
            </label>
            <textarea
              id="textBox"
              name="textBox"
              value={value}
              onChange={handleTextChange}
              placeholder={data?.placeholder || "Write something..."}
              rows={4}
              className="block w-full resize-y bg-yellow-50 border border-yellow-700 py-1 text-gray-600 placeholder:text-gray-400 focus:ring-0 text-sm overflow-auto rounded-md"
            />
            <div className="flex justify-end mt-2 gap-x-2">
              <button
                type="button"
                className="inline-flex items-center gap-x-2 rounded-md bg-green-200 px-3 py-2 text-sm font-semibold text-gray shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                onClick={handleLoadFromPromptLibrary}
              >
                Prompt Library
              </button>
              <button
                type="button"
                className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => console.log("Updated with value:", value)}
              >
                Update
              </button>
            </div>
          </div>
        ) : (
          <input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="appearance-none w-full h-8 px-2 rounded-md bg-yellow-50 border border-yellow-700 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-200"
          />
        )}
      </div>

      {/* Handle on the right, positioned exactly at the boundary */}
      <Handle
        type="source"
        position={Position.Right}
        id="source_1"
        className="rounded-full w-2 h-2"
        style={{
          right: "-3px", // Move it slightly outside the boundary
          top: "50%",
          transform: "translateY(-50%)", // Vertically center it
        }}
      />
    </div>
  );
}

export default VariableNode;
