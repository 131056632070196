import React, { useEffect } from "react";
import * as d3 from "d3";

const LinePlot = ({
  datasets,
  plotWidth,
  plotHeight,
  xRange = [0, 2 * Math.PI],
  yRange = [-1, 1],
  title = "Line Plot Example",
  xLabel = "X Axis Label",
  yLabel = "Y Axis Label",
  showGrid = true,
  xTicks = 10, // Default value for x ticks
  yTicks = 5, // Default value for y ticks
}) => {
  useEffect(() => {
    const svgElement = d3.select("#linePlotSVG");

    const width = plotWidth - 100;
    const height = plotHeight;
    const margin = { top: 50, right: 20, bottom: 50, left: 60 };

    // Clear existing content
    svgElement.selectAll("*").remove();

    // Create scales
    const xScale = d3.scaleLinear().domain(xRange).range([0, width]);
    const yScale = d3.scaleLinear().domain(yRange).range([height, 0]);

    // Append main plot area
    const svg = svgElement
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Add title
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text(title);

    // Add X axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(xScale).ticks(xTicks)); // Use the xTicks parameter

    // Add X-axis label
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height + margin.bottom - 10)
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .text(xLabel);

    // Add Y axis
    svg.append("g").call(d3.axisLeft(yScale).ticks(yTicks)); // Use the yTicks parameter

    // Add Y-axis label
    svg
      .append("text")
      .attr("x", -height / 2)
      .attr("y", -margin.left + 20)
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .attr("font-size", "12px")
      .text(yLabel);

    // Optionally add grid
    if (showGrid) {
      svg
        .append("g")
        .attr("class", "grid")
        .attr("transform", `translate(0,${height})`)
        .call(
          d3.axisBottom(xScale).ticks(xTicks).tickSize(-height).tickFormat("")
        ) // Use xTicks for grid
        .attr("stroke-opacity", 0.1);

      svg
        .append("g")
        .attr("class", "grid")
        .call(d3.axisLeft(yScale).ticks(yTicks).tickSize(-width).tickFormat("")) // Use yTicks for grid
        .attr("stroke-opacity", 0.1);
    }

    // Plot each dataset
    datasets.forEach((dataset) => {
      const line = d3
        .line()
        .x((d) => xScale(d.x))
        .y((d) => yScale(d.y))
        .curve(d3.curveBasis);

      svg
        .append("path")
        .datum(dataset.data)
        .attr("fill", "none")
        .attr("stroke", dataset.color)
        .attr("stroke-width", dataset.strokeWidth || 2) // Default stroke width is 2
        .attr("stroke-opacity", dataset.alpha || 1) // Default opacity is 1
        .attr("d", line);
    });
  }, [
    datasets,
    plotWidth,
    plotHeight,
    xRange,
    yRange,
    title,
    xLabel,
    yLabel,
    showGrid,
    xTicks,
    yTicks,
  ]);

  return <svg id="linePlotSVG"></svg>;
};

export default LinePlot;
