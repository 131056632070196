import React, { useEffect } from "react";
import * as d3 from "d3";

const GaussianProcessPlot = ({
  data, // Array of { x, y, stdDev }
  plotWidth,
  plotHeight,
  xLabel = "X",
  yLabel = "Y",
  xRange = [0, 10],
  yRange = [-2, 2],
  showGrid = true,
}) => {
  useEffect(() => {
    const svgElement = d3.select("#gaussianProcessPlotSVG");

    const width = plotWidth - 100;
    const height = plotHeight;
    const margin = { top: 50, right: 20, bottom: 50, left: 60 };

    // Clear existing content
    svgElement.selectAll("*").remove();

    // Create scales
    const xScale = d3.scaleLinear().domain(xRange).range([0, width]);
    const yScale = d3.scaleLinear().domain(yRange).range([height, 0]);

    // Append main plot area
    const svg = svgElement
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Add X axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(xScale));

    // Add Y axis
    svg.append("g").call(d3.axisLeft(yScale));

    // Optionally add grid
    if (showGrid) {
      svg
        .append("g")
        .attr("class", "grid")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(xScale).tickSize(-height).tickFormat(""))
        .attr("stroke-opacity", 0.1);

      svg
        .append("g")
        .attr("class", "grid")
        .call(d3.axisLeft(yScale).tickSize(-width).tickFormat(""))
        .attr("stroke-opacity", 0.1);
    }

    // Create the area generator for the shaded region
    const area = d3
      .area()
      .x((d) => xScale(d.x))
      .y0((d) => yScale(d.y - d.stdDev)) // Lower bound (y - stdDev)
      .y1((d) => yScale(d.y + d.stdDev)) // Upper bound (y + stdDev)
      .curve(d3.curveBasis); // Smooth the curve

    // Append the area (shaded region between y ± stdDev)
    svg
      .append("path")
      .datum(data)
      .attr("fill", "lightblue")
      .attr("stroke", "none")
      .attr("d", area);

    // Create the line generator for the mean
    const line = d3
      .line()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y))
      .curve(d3.curveBasis);

    // Append the mean line
    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 2)
      .attr("d", line);

    // Add X-axis label
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height + margin.bottom - 10)
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .text(xLabel);

    // Add Y-axis label
    svg
      .append("text")
      .attr("x", -height / 2)
      .attr("y", -margin.left + 20)
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .attr("font-size", "12px")
      .text(yLabel);
  }, [data, plotWidth, plotHeight, xRange, yRange, xLabel, yLabel, showGrid]);

  return <svg id="gaussianProcessPlotSVG"></svg>;
};

export default GaussianProcessPlot;
