export default function loadNodeConfigs() {
  const context = require.context("../components/nodeConfigs", true, /\.js$/);

  const nodeConfigs = context.keys().map((key, index) => {
    const config = context(key).default;
    return {
      id: index + 1,
      title: config.data.title,
      description: config.data.shortdescription || " ",
      type: config.data.type,
      data: config.data,
      action: config.data.action,
    };
  });

  return nodeConfigs;
}
