export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-pink-200",
    title: "VTK Uploader",
    subclass: "Export / Visualisation",
    type: "input",
    action: "vtkUploader",
    shortdescription: `
    Generate a Line plot, as an output.
        `,
    description: `
This function makes a line plot from data.
    `,
    numTargets: 1,
    numSources: 2,
    targetLabels: ["fileName"],
    sourceLabels: ["Nodes", "Elements"],
  },
};
