export default {
  data: {
    title: "File",
    headerColor: "bg-yellow-200",
    shortdescription: `
    This allows you to point to a file within the project.
        `,
    description:
      "This node allows a user to define a natural language prompt used to guid an LLM",
    action: "var_file",
    unitWidth: "400px",
    leftColumnPercentage: 20,
    menuWidth: "200px",
    type: "var",
    formType: "text",
    label: "Filename",
    subclass: "Variable",
  },
};
