export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-pink-200",
    title: "FEM Viewer",
    subclass: "Export / Visualisation",
    type: "plot",
    action: "femViewer",
    shortdescription: `
    Generate a Line plot, as an output.
        `,
    description: `
This function makes a line plot from data.
    `,
    numTargets: 3,
    numSources: 0,
    targetLabels: ["Nodes", "Elements", "Solution Vector"],
    sourceLabels: [],
  },
};
