import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import {
  MagnifyingGlassPlusIcon,
  MagnifyingGlassMinusIcon,
  ArrowsPointingInIcon,
  ArrowPathIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";

/**
 * Sidebar component provides quick actions.
 *
 * @param {Object} props - Component props.
 * @param {function} props.onZoomIn - Action for zooming in.
 * @param {function} props.onZoomOut - Action for zooming out.
 * @param {function} props.onPanCenter - Action to pan to the center.
 * @param {function} props.onFitView - Action to fit view.
 * @param {function} props.onUndo - Action to undo.
 * @param {function} props.onRedo - Action to redo.
 * @param {function} props.onLockNodes - Action to lock nodes.
 */

const Sidebar = ({
  onZoomIn,
  onZoomOut,
  onPanCenter,
  onFitView,
  onUndo,
  onRedo,
  onLockNodes,
}) => {
  const actions = [
    {
      id: "zoomIn",
      icon: <MagnifyingGlassPlusIcon className="h-6 w-6 text-gray-600" />,
      action: onZoomIn,
      name: "Zoom In",
    },
    {
      id: "zoomOut",
      icon: <MagnifyingGlassMinusIcon className="h-6 w-6 text-gray-600" />,
      action: onZoomOut,
      name: "Zoom Out",
    },
    {
      id: "panCenter",
      icon: <ArrowsPointingInIcon className="h-6 w-6 text-gray-600" />,
      action: onPanCenter,
      name: "Pan to Center",
    },
    {
      id: "fitView",
      icon: <ArrowPathIcon className="h-6 w-6 text-gray-600" />,
      action: onFitView,
      name: "Fit View",
    },
    {
      id: "undo",
      icon: <ArrowUturnLeftIcon className="h-6 w-6 text-gray-600" />,
      action: onUndo,
      name: "Undo",
    },
    {
      id: "redo",
      icon: <ArrowUturnRightIcon className="h-6 w-6 text-gray-600" />,
      action: onRedo,
      name: "Redo",
    },
    {
      id: "lock",
      icon: <LockClosedIcon className="h-6 w-6 text-gray-600" />,
      action: onLockNodes,
      name: "Lock Nodes",
    },
  ];

  return (
    <div className="flex">
      {/* Sidebar */}
      <div className="w-16 bg-white border border-gray-400 rounded-lg p-2 flex flex-col items-center space-y-1">
        {actions.map((action) => (
          <button
            key={action.id}
            className="flex items-center justify-center w-full p-2 hover:bg-gray-100 rounded"
            onClick={action.action} // Call the action function
          >
            {action.icon}
          </button>
        ))}
      </div>
    </div>
  );
};

// Define the PropTypes for the component
Sidebar.propTypes = {
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  onPanCenter: PropTypes.func.isRequired,
  onFitView: PropTypes.func.isRequired,
  onUndo: PropTypes.func.isRequired,
  onRedo: PropTypes.func.isRequired,
  onLockNodes: PropTypes.func.isRequired,
};

export default Sidebar;
