// App.js
import { useState, useMemo } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import WorkflowCanvas from "./WorkflowCanvas";
import AppCanvas from "./AppCanvas";
import SettingsPage from "./SettingsPage";

import useTabs from "./utilities/useTabs";
import { getTabType } from "./utilities/dbUtils";

function App() {
  const [currentUser, setCurrentUser] = useState("u3"); // Set the current user

  const tabsData = useTabs();

  // Determine the component to render based on the tab type
  const renderCanvas = () => {
    const tabType = getTabType(tabsData.currentTabId);
    if (tabType === "workflow") {
      return <WorkflowCanvas currentUser={currentUser} useTabs={tabsData} />;
    } else if (tabType === "model") {
      return <WorkflowCanvas currentUser={currentUser} useTabs={tabsData} />;
    } else if (tabType === "app") {
      return <AppCanvas currentUser={currentUser} useTabs={tabsData} />;
    }
    return "Something went wrong!";
  };

  return (
    <Router>
      {/* Main content area where the different pages will render */}
      <div>
        <Routes>
          {/* The default route will load WorkflowCanvas */}
          <Route path="/" element={renderCanvas()} />

          {/* Route to settings page */}
          <Route
            path="/settings"
            element={<SettingsPage currentUser={currentUser} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
