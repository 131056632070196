export default {
  data: {
    title: "Add Float",
    headerColor: "bg-yellow-200",
    shortdescription: `
    This node allows the user to input a float.
        `,
    description: "This node allows the user to input a float.",
    action: "var_float",
    unitWidth: "200px",
    menuWidth: "200px",
    type: "var",
    formType: "float",
    label: "Number",
    subclass: "Variable",
    description: `
      This allows user to input a float as a variable.
      `,
  },
};
