export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-green-200",
    title: "CSV Uploader",
    type: "input",
    subclass: "Input",
    shortdescription: `
Upload data from a spreadsheet.
    `,
    description: `
Here I should put a description about what a Mulitvariant Sampler is!
    `,
    numTargets: 2,
    numSources: 1,
    targetLabels: ["Filename", "Variable"],
    sourceLabels: ["Data"],
    params: {},
    action: "csvUploader",
  },
};
