export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-green-200",
    title: "Vector Store",
    action: "vector_store",
    type: "input",
    subclass: "Input",
    shortdescription: `
Vectorised database
    `,
    description: `
    Vectorised database
    `,
    numTargets: 0,
    numSources: 1,
    targetLabels: [],
    sourceLabels: ["Vector Store"],
    params: {
      modelName: "No Model Selected",
      modelSelected: false,
    },
    nodeStyle: "modalInput",
  },
};
