export default {
  data: {
    unitWidth: "300px",
    headerColor: "bg-yellow-200",
    title: "Uniform Grid",
    subclass: "Variable",
    type: "var",
    action: "uniformgrid",
    shortdescription: `
    Construct a uniform grid.
        `,
    description: `
**Uniform Grid** returns an array of N equally space points between a min and max value. It has no **input** values, and a single **output** which is these value. $N$, and the min and max values are **parameters**.
    `,
    numTargets: 3,
    numSources: 1,
    targetLabels: ["Number of Grid Points", "xmin", "xmax"],
    sourceLabels: ["Uniform Grid"],
    params: {
      N: 10,
      xmin: 0.0,
      xmax: 1.0,
    },
  },
};
