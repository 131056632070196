export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-blue-200",
    title: "Experimental Design",
    action: "bed",
    type: "ml",
    subclass: "Machine Learning Method",
    shortdescription: `
Bayesian Experimental Design
    `,
    description: `
This is the longer thinning description
    `,
    numTargets: 3,
    numSources: 2,
    targetLabels: ["Samples", "Objective", "Optimiser"],
    sourceLabels: ["Candidate Design", "Diagnostics"],
    targetTypes: ["pd.dataframe", "func", "optimiser"],
    soureTypes: ["pd.dataframe", "dict"],
    params: {},
  },
};
