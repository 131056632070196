export default {
  data: {
    title: "Add a Prompt",
    headerColor: "bg-yellow-200",
    shortdescription: `
    This node allows a user to define a natural language prompt used to guid an LLM
        `,
    description:
      "This node allows a user to define a natural language prompt used to guid an LLM",
    action: "var_prompt",
    unitWidth: "400px",
    leftColumnPercentage: 20,
    menuWidth: "200px",
    type: "var",
    formType: "textBox",
    label: "Prompt",
    subclass: "Variable",
  },
};
