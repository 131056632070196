export const addNewNode = async (
  nodes,
  setNodes,
  node_type,
  edges = [],
  updateNodeData,
  onEdit
) => {
  try {
    // Check if node_type is "slider"
    if (!node_type.startsWith("var")) {
      // Dynamically import the configuration file based on the node_type
      const nodeConfig = await import(
        `../components/nodeConfigs/${node_type}.js`
      );

      // Create a new node using the imported configuration
      const newNode = {
        id: (nodes.length + 1).toString(),
        data: {
          ...nodeConfig.default.data,
          updateNodeData, // Include the updateNodeData function
          onEdit,
        },
        type: "base", // Assuming 'base' type for non-slider nodes
        position: {
          x: Math.random() * 400,
          y: Math.random() * 400,
        },
      };
      // Add the new node to the state
      setNodes((nds) => [...nds, newNode]);
    } else {
      const nodeConfig = await import(
        `../components/nodeConfigs/Variables/${node_type}.js`
      );
      const newVariableNode = {
        id: (nodes.length + 1).toString(),
        data: {
          ...nodeConfig.default.data,
          updateNodeData, // Include the updateNodeData function
          onEdit,
        },
        type: "variable", // Assuming you have a 'sliderNode' type defined in your nodeTypes
        position: {
          x: Math.random() * 400,
          y: Math.random() * 400,
        },
      };

      // Add the new slider node to the state
      setNodes((nds) => [...nds, newVariableNode]);
    }
  } catch (error) {
    console.error(
      `Error loading node configuration for type: ${node_type}`,
      error
    );
  }
};
