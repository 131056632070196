import React, { useState, useCallback, useEffect } from "react";
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
  Background,
  BackgroundVariant,
} from "@xyflow/react";

import { PlusIcon } from "@heroicons/react/24/outline";

import "@xyflow/react/dist/base.css";
import "./App.css";

import Header from "./Header";
import { addNewNode } from "./utilities/nodeUtils";
import nodeTypes from "./utilities/nodeTypes";
import RightDrawer from "./components/Navigation/RightDrawer";
import Breadcrumb from "./components/Navigation/Breadcrumb";

import Sidebar from "./components/Navigation/sidebar";
import RightToolbar from "./components/Navigation/RightToolbar";
import FloatingActionButtons from "./components/Navigation/FloatingActionButtons";

import EditNodeModal from "./components/Nodes/Modals/EditNodeModal";
import NewCanvasModal from "./components/Nodes/Modals/NewCanvasModal";
import ManageProjectSharing from "./components/Nodes/Modals/ManageProjectSharingModal";

import RightDrawerPlotting from "./components/Navigation/RightDrawerPlotting";

function WorkflowCanvas() {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [connectedInfo, setConnectedInfo] = useState(null);
  const [activeTab, setActiveTab] = useState("summary");
  const [displayInfo, setDisplayInfo] = useState({ nodes: [], edges: [] });

  const [activeModal, setActiveModal] = useState(null); // Manages which modal is open
  const [preSelectedType, setPreSelectedType] = useState("all");

  const [currentNodeData, setCurrentNodeData] = useState(null);

  const [isFavorite, setIsFavorite] = useState(false);
  const [isDraft, setIsDraft] = useState(true);

  const [projectName, setProjectName] = useState("FullGyroX");
  const [workflowName, setWorkflowName] = useState("BalloningNodes.flw");

  useEffect(() => {
    setDisplayInfo({
      nodes: nodes,
      edges: edges,
    });
  }, [nodes, edges]);

  const findConnectedNodeAndHandle = (nodeId, handleId) => {
    const edge = edges.find(
      (edge) => edge.target === nodeId && edge.targetHandle === handleId
    );

    if (edge) {
      const sourceNodeId = edge.source;
      const sourceHandleId = edge.sourceHandle;

      setConnectedInfo({
        sourceNodeId,
        sourceHandleId,
        targetNodeId: nodeId,
        targetHandleId: handleId,
      });
    }
  };

  const [highlightedNodes, setHighlightedNodes] = useState([]);

  const onNodeClick = (event, node) => {
    const connectedEdges = edges.filter((edge) => edge.source === node.id);
    const connectedNodes = connectedEdges.map((edge) => edge.target);
    setHighlightedNodes(connectedNodes);
  };

  const handleAddNode = (type = "defaultType") => {
    setPreSelectedType(type); // Optionally set a default node type
    setActiveModal("EditNodeModal"); // Open the modal
  };

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({ ...params, markerEnd: { type: "arrowclosed" } }, eds)
      ),
    []
  );

  const updateNodeData = (nodeId, newData) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === nodeId
          ? { ...node, data: { ...node.data, ...newData } }
          : node
      )
    );
  };

  const openModalWithNodeData = (nodeId, nodeData) => {
    setCurrentNodeData({ id: nodeId, data: nodeData });
    setActiveModal("EditNodeModal"); // Set active modal to EditNodeModal
  };

  const closeModal = () => {
    setActiveModal(null); // Close all modals
    setCurrentNodeData(null);
  };

  const handleBoltClick = () => {
    setActiveTab("run");
    setIsDrawerOpen(true);
  };

  const handleChatClick = () => {
    setActiveTab("chat");
    setIsDrawerOpen(true);
  };

  const handleInfoClick = () => {
    setActiveTab("info");
    setIsDrawerOpen(true);
  };

  return (
    <div
      className="workflow-canvas"
      style={{ overflow: "hidden", height: "100vh" }}
    >
      <Header
        className="fixed top-0 left-0 right-0 z-20"
        setActiveModal={setActiveModal}
      />

      <div className="min-h-full relative pt-16">
        <div
          className="relative bg-gray-100"
          style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            fitView
            className="absolute top-0 left-0 w-full h-full z-0 bg-gray-200"
          >
            <Background
              id="2"
              gap={20}
              color="#111827"
              variant={BackgroundVariant.Dots}
            />
          </ReactFlow>

          <div className="absolute top-6 left-0 right-0 z-10 flex justify-between items-center px-4 h-20">
            <Breadcrumb
              projectName={projectName}
              workflowName={workflowName}
              isFavorite={isFavorite}
              isDraft={isDraft}
              setIsFavorite={setIsFavorite}
              setIsDraft={setIsDraft}
            />
            <RightToolbar setActiveModal={setActiveModal} />
          </div>

          <FloatingActionButtons
            onBoltClick={handleBoltClick}
            onChatClick={handleChatClick}
            onInfoClick={handleInfoClick}
          />

          <div
            className="absolute left-0 right-0 z-10 flex justify-between items-center px-4 h-20"
            style={{ top: "40%", transform: "translateY(-50%)" }}
          >
            <Sidebar
              nodes={nodes}
              setNodes={setNodes}
              setActiveModal={setActiveModal} // Pass the modal setter function
              preSelectedType={preSelectedType}
              setPreSelectedType={setPreSelectedType}
            />
          </div>

          <RightDrawer
            open={isDrawerOpen}
            setOpen={setIsDrawerOpen}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            displayInfo={displayInfo}
          />

          {/* Add Node Button */}
          <div className="absolute bottom-25 left-6">
            <button
              onClick={() => handleAddNode("all")} // Specify the default type or leave as "defaultType"
              className="w-20 h-20 rounded-full bg-white border border-gray-300 shadow-lg flex items-center justify-center hover:bg-[#162448] hover:text-white transition duration-300"
            >
              <PlusIcon className="h-10 w-10 text-gray-600 hover:text-white" />
            </button>
          </div>

          {/* Conditionally render modals based on activeModal */}
          {activeModal === "EditNodeModal" && (
            <EditNodeModal
              nodes={nodes} // Pass nodes state
              setNodes={setNodes} // Pass setNodes function
              preSelectedType={preSelectedType} // Pass the pre-selected type
              open={true}
              setOpen={setActiveModal} // To close the modal
            />
          )}

          {activeModal === "NewCanvasModal" && (
            <NewCanvasModal
              open={true}
              setOpen={setActiveModal} // To close the modal
            />
          )}

          {activeModal === "ManageProjectSharingModal" && (
            <ManageProjectSharing
              open={true}
              setOpen={setActiveModal} // To close the modal
            />
          )}

          {/* You can add more modals here and manage them through activeModal */}
        </div>
      </div>
    </div>
  );
}

export default WorkflowCanvas;
