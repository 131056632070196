import React, { memo, useState, useRef, useEffect } from "react";
import { Handle, Position } from "@xyflow/react";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import {
  InformationCircleIcon,
  ChevronUpIcon,
  PencilSquareIcon,
  DocumentDuplicateIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";

import MarkdownRenderer from "../../utilities/MarkdownRenderer";
import { data } from "autoprefixer";

const statusColorMap = {
  green: "bg-green-400",
  amber: "bg-yellow-400",
  red: "bg-red-400",
};

// Function to dynamically generate handle configurations
const generateHandles = (numTargets, numSources) => {
  const handles = [];

  const handleDiv = 30 * (Math.max(numTargets, numSources) + 1);
  const offsetTarget = handleDiv / (numTargets + 1);
  const offsetSource = handleDiv / (numSources + 1);

  // Generate target handles
  for (let i = 1; i <= numTargets; i++) {
    handles.push({
      id: `target_${i}`,
      type: "target",
      position: Position.Left,
      style: { top: 60 + offsetTarget * i },
    });
  }

  // Generate source handles
  for (let i = 1; i <= numSources; i++) {
    handles.push({
      id: `source_${i}`,
      type: "source",
      position: Position.Right,
      style: { top: 60 + offsetSource * i },
    });
  }

  return {
    handles,
    handleDiv,
    offsetTarget,
    offsetSource,
  };
};

function BaseNode({ data }) {
  const {
    unitWidth,
    title,
    headerColor,
    description,
    onEdit,
    updateNodeData,
    children,
    numTargets,
    numSources,
    targetLabels,
    sourceLabels,
  } = data;

  const heightHandleDiv =
    (30 * (Math.max(numTargets, numSources) + 1)).toString() + "px";

  const { handles, handleDiv, offsetTarget, offsetSource } = generateHandles(
    numTargets,
    numSources
  );

  const [status, setStatus] = useState("amber");
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => setIsExpanded(!isExpanded);

  return (
    <div className="rounded-lg border border-gray-400">
      <div
        className="relative border-b border-gray-900/5 bg-gray-50 p-0 rounded-lg"
        style={{ width: unitWidth }}
      >
        <span
          className={`absolute -top-1 -right-1 block h-4 w-4 rounded-full ring-2 ring-white ${statusColorMap[status]}`}
        />

        <div
          className={`flex items-center justify-between ${headerColor} py-2 px-4 rounded-t-lg`}
          style={{ width: unitWidth, height: "60px" }}
        >
          <div className="text-lg font-bold leading-6 text-gray-900">
            {title}
          </div>

          {/* Dropdown menu button for options */}
          <Menu as="div" className="relative inline-block text-left">
            <MenuButton className="inline-flex w-full justify-center rounded-md text-gray-500 hover:text-gray-700">
              <svg
                className="h-8 w-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6.75a.75.75 0 100 1.5.75.75 0 000-1.5zm0 4.5a.75.75 0 100 1.5.75.75 0 000-1.5zm0 4.5a.75.75 0 100 1.5.75.75 0 000-1.5z"
                />
              </svg>
            </MenuButton>

            <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <MenuItem>
                  {({ active }) => (
                    <a
                      href="#"
                      onClick={onEdit}
                      className={`group flex items-center px-4 py-2 text-sm text-gray-700 ${active ? "bg-gray-100 text-gray-900" : ""}`}
                    >
                      <PencilSquareIcon
                        aria-hidden="true"
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      />
                      Edit / View Parameters
                    </a>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ active }) => (
                    <a
                      href="#"
                      className={`group flex items-center px-4 py-2 text-sm text-gray-700 ${active ? "bg-gray-100 text-gray-900" : ""}`}
                    >
                      <DocumentDuplicateIcon
                        aria-hidden="true"
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      />
                      Duplicate
                    </a>
                  )}
                </MenuItem>
              </div>
              <div className="py-1">
                <MenuItem>
                  {({ active }) => (
                    <a
                      href="#"
                      className={`group flex items-center px-4 py-2 text-sm text-gray-700 ${active ? "bg-gray-100 text-gray-900" : ""}`}
                    >
                      <TrashIcon
                        aria-hidden="true"
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      />
                      Delete
                    </a>
                  )}
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        </div>

        <div
          className="flex flex-row items-start p-4 bg-gray-175 relative rounded-b-lg"
          style={{ width: unitWidth, height: heightHandleDiv }}
        >
          {/* Left Section */}
          <div className="flex-1 flex flex-col">
            {Array.from({ length: numTargets }).map((_, index) => (
              <div
                className="flex-1 text-sm text-gray-600"
                style={{
                  position: "absolute",
                  top: (index + 1) * offsetTarget + "px",
                  left: "8px",
                  right: "0",
                  transform: "translateY(-55%)",
                }}
              >
                {targetLabels[index] || `Target ${index + 1}`}
              </div>
            ))}
          </div>

          {/* Right Section */}
          <div
            className="flex-1 flex flex-col text-right justify-center"
            style={{ height: heightHandleDiv }}
          >
            {/* Divide into numSource rows of equal height */}
            {Array.from({ length: numSources }).map((_, index) => (
              <div
                className="flex-1 text-sm text-gray-600"
                style={{
                  position: "absolute",
                  top: (index + 1) * offsetSource + "px",
                  left: "0px",
                  right: "8px",
                  transform: "translateY(-55%)",
                }}
              >
                {sourceLabels[index] || `Source ${index + 1}`}
              </div>
            ))}
          </div>

          {/* Your existing button with toggle functionality */}
          <button
            type="button"
            onClick={toggleExpansion}
            className="absolute bottom-1 right-1 text-gray-500 hover:text-gray-800"
          >
            {isExpanded ? (
              <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
            ) : (
              <InformationCircleIcon className="h-5 w-5" aria-hidden="true" />
            )}
          </button>
        </div>

        {isExpanded && (
          <div
            className="divide-y divide-gray-100 px-6 py-4 text-sm text-gray- leading-6 bg-yellow-50 rounded-lg border-gray-400"
            style={{ width: unitWidth }}
          >
            <MarkdownRenderer markdownString={description} />
          </div>
        )}
      </div>

      {handles.map((handleConfig) => (
        <Handle
          key={handleConfig.id} // Add a unique key for each Handle component
          id={handleConfig.id}
          type={handleConfig.type}
          position={handleConfig.position} // Use position from handleConfig
          className="rounded-full w-2 h-2 !bg-black"
          style={handleConfig.style}
        />
      ))}
    </div>
  );
}

export default memo(BaseNode);
