import React from "react";
import {
  BoltIcon,
  ChatBubbleLeftRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";

const FloatingActionButtons = ({ onBoltClick, onChatClick, onInfoClick }) => {
  return (
    <div className="absolute bottom-25 right-8 flex flex-col space-y-4">
      <button
        onClick={onBoltClick} // Handle click for the bolt button
        className="w-14 h-14 rounded-full bg-white border border-gray-300 shadow-lg flex items-center justify-center hover:bg-[#162448] hover:text-white transition duration-300"
      >
        <BoltIcon className="h-6 w-6 text-gray-600 hover:text-white" />
      </button>
      <button
        onClick={onChatClick} // Handle click for the chat button
        className="w-14 h-14 rounded-full bg-white border border-gray-300 shadow-lg flex items-center justify-center hover:bg-[#162448] hover:text-white transition duration-300"
      >
        <ChatBubbleLeftRightIcon className="h-6 w-6 text-gray-600 hover:text-white" />
      </button>
      <button
        onClick={onInfoClick} // Handle click for the info button
        className="w-14 h-14 rounded-full bg-white border border-gray-300 shadow-lg flex items-center justify-center hover:bg-[#162448] hover:text-white transition duration-300"
      >
        <InformationCircleIcon className="h-6 w-6 text-gray-600 hover:text-white" />
      </button>
    </div>
  );
};

export default FloatingActionButtons;
