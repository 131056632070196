export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-teal-200",
    title: "Retrieval Tool",
    action: "retrieve",
    type: "rag",
    subclass: "Agentic",
    shortdescription: `
Retrieve relevant information from a vector store.
    `,
    description: `
    Retrieve relevant information from a vector store.
    `,
    numTargets: 3,
    numSources: 1,
    targetLabels: ["Message", "LLM", "Vector Store"],
    sourceLabels: ["Relevant Info."],
    params: {},
  },
};
