import React, { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import LinePlot from "../Visualisation/LinePlot";
import GaussianProcessPlot from "../Visualisation/GaussianProcessPlot";

export default function RightDrawerPlotting({ open, setOpen }) {
  const [width, setWidth] = useState(400); // Initial width of the drawer
  const maxPlotWidth = 600; // Maximum width for the plot

  // Form state for the inputs
  const [title, setTitle] = useState("Line Plot Example");
  const [xLabel, setXLabel] = useState("X Axis Label");
  const [yLabel, setYLabel] = useState("Y Axis Label");
  const [xRange, setXRange] = useState([0, 2 * Math.PI]);
  const [yRange, setYRange] = useState([-1, 1]);
  const [showGrid, setShowGrid] = useState(true);
  const [xTicks, setXTicks] = useState(10);
  const [yTicks, setYTicks] = useState(5);

  // State for updating the plot
  const [plotConfig, setPlotConfig] = useState({
    title: "Line Plot Example",
    xLabel: "X Axis Label",
    yLabel: "Y Axis Label",
    xRange: [0, 2 * Math.PI],
    yRange: [-1, 1],
    showGrid: true,
    xTicks: 10,
    yTicks: 5,
  });

  const handleUpdatePlot = () => {
    // Update the plotConfig state when the "Update Plot" button is clicked
    setPlotConfig({
      title,
      xLabel,
      yLabel,
      xRange,
      yRange,
      showGrid,
      xTicks,
      yTicks,
    });
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    const startX = e.clientX;
    const startWidth = width;

    const handleMouseMove = (e) => {
      const newWidth = startWidth - (e.clientX - startX);
      if (newWidth >= 300 && newWidth <= window.innerWidth - 100) {
        setWidth(newWidth);
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  // Example datasets (sine and cosine waves with different strokeWidth)
  const datasets = [
    {
      data: Array.from({ length: 100 }, (_, i) => {
        const x = (i / 100) * 2 * Math.PI;
        const stdDev = 0.2 + Math.random() * 0.1; // Some random standard deviation
        return { x, y: Math.sin(x), stdDev };
      }),
      color: "steelblue", // Color for sine wave
      alpha: 0.3,
      strokeWidth: 2,
    },
    {
      data: Array.from({ length: 100 }, (_, i) => {
        const x = (i / 100) * 2 * Math.PI;
        const stdDev = 0.2 + Math.random() * 0.1; // Some random standard deviation
        return { x, y: Math.cos(x), stdDev };
      }),
      color: "orange", // Color for cosine wave
      alpha: 0.7,
      strokeWidth: 3,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="fixed inset-0 z-50"
    >
      <div className="fixed inset-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex pl-10 sm:pl-16">
            <DialogPanel
              transition
              style={{ width: width }} // Apply the dynamic width
              className="pointer-events-auto h-full transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700 bg-white shadow-xl"
            >
              <form className="flex h-full flex-col divide-y divide-gray-200">
                <div className="h-0 flex-1 overflow-y-auto">
                  {/* Top Bar */}
                  <div className="bg-gray-100 px-4 py-6 sm:px-6">
                    <div className="flex items-center justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-black">
                        Line Plot Viewer / Editor
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="relative rounded-md bg-gray-100 text-gray-500 hover:text-black focus:outline-none focus:ring-2 focus:ring-gray-500"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Plot Container */}
                  <div className="flex flex-1 justify-center items-center px-4 py-4 sm:px-6">
                    {/* Dynamically set the plot size and center it */}
                    <div
                      style={{
                        maxWidth: maxPlotWidth,
                        width: Math.min(width - 40, maxPlotWidth), // Dynamic plot size with a max width
                        padding: "20px", // Padding around the plot
                        backgroundColor: "#f8f8f8",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      }}
                    >
                      <LinePlot
                        datasets={datasets}
                        plotWidth={Math.min(width - 40, maxPlotWidth)}
                        plotHeight={300}
                        {...plotConfig}
                      />
                    </div>
                  </div>

                  {/* Form for setting plot parameters */}
                  <div className="px-4 py-6 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Plot Settings
                    </h3>
                    <div className="mt-6 space-y-6">
                      {/* Inline input form */}
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        {/* Title input */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Plot Title
                          </label>
                          <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>

                        {/* X-axis label */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            X-Axis Label
                          </label>
                          <input
                            type="text"
                            value={xLabel}
                            onChange={(e) => setXLabel(e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>

                        {/* Y-axis label */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Y-Axis Label
                          </label>
                          <input
                            type="text"
                            value={yLabel}
                            onChange={(e) => setYLabel(e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>

                        {/* X-axis range */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            X-Axis Range
                          </label>
                          <div className="mt-1 flex space-x-2">
                            <input
                              type="number"
                              value={xRange[0]}
                              onChange={(e) =>
                                setXRange([Number(e.target.value), xRange[1]])
                              }
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="Min"
                            />
                            <input
                              type="number"
                              value={xRange[1]}
                              onChange={(e) =>
                                setXRange([xRange[0], Number(e.target.value)])
                              }
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="Max"
                            />
                          </div>
                        </div>

                        {/* Y-axis range */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Y-Axis Range
                          </label>
                          <div className="mt-1 flex space-x-2">
                            <input
                              type="number"
                              value={yRange[0]}
                              onChange={(e) =>
                                setYRange([Number(e.target.value), yRange[1]])
                              }
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="Min"
                            />
                            <input
                              type="number"
                              value={yRange[1]}
                              onChange={(e) =>
                                setYRange([yRange[0], Number(e.target.value)])
                              }
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="Max"
                            />
                          </div>
                        </div>

                        {/* X-axis ticks */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            X-Axis Ticks
                          </label>
                          <input
                            type="number"
                            value={xTicks}
                            onChange={(e) => setXTicks(Number(e.target.value))}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>

                        {/* Y-axis ticks */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Y-Axis Ticks
                          </label>
                          <input
                            type="number"
                            value={yTicks}
                            onChange={(e) => setYTicks(Number(e.target.value))}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>

                        {/* Show Grid */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Show Grid
                          </label>
                          <input
                            type="checkbox"
                            checked={showGrid}
                            onChange={() => setShowGrid(!showGrid)}
                            className="mt-1 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                        </div>
                      </div>

                      {/* Buttons for updating plot */}
                      <div className="flex gap-x-4">
                        <button
                          type="button"
                          onClick={handleUpdatePlot}
                          className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          <ArrowPathIcon
                            aria-hidden="true"
                            className="-ml-0.5 h-5 w-5"
                          />
                          Update Plot
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div
                onMouseDown={handleMouseDown}
                className="absolute top-0 left-0 h-full w-2 cursor-ew-resize bg-gray-300"
                style={{ transform: "translateX(-2px)" }}
              ></div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
