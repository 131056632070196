export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-pink-200",
    title: "Line Plot",
    subclass: "Export / Visualisation",
    type: "plot",
    action: "plotline1D",
    shortdescription: `
    Generate a Line plot, as an output.
        `,
    description: `
This function makes a line plot from data.
    `,
    numTargets: 4,
    numSources: 0,
    targetLabels: ["X-Axis", "Y-Axis", "Color", "Alpha"],
    sourceLabels: [],
    params: {
      title: "Plot",
      xaxisLabel: "X-Axis",
      yaxisLabel: "Y-Axis",
    },
  },
};
