export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-blue-200",
    title: "twinLab Model",
    type: "input",
    subclass: "Input",
    shortdescription: `
Include a registered twinLab Model in your workflow.
    `,
    description: `
This allows you to add a twinLab Model you have build, validated and registered within a twinLab workflow.
    `,
    numTargets: 0,
    numSources: 2,
    targetLabels: [],
    sourceLabels: ["tL Model", "Model Info."],
    params: {},
    action: "twinLabModel",
  },
};
