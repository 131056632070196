export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-green-200",
    title: "Prior Distribution",
    subclass: "Input",
    type: "input",
    action: "prior",
    shortdescription: `
    Sample from a the prior distribution of a twinLab model.
        `,
    description: `
Here I should put a description about what a Mulitvariant Sampler is!
    `,
    numTargets: 3,
    numSources: 1,
    targetLabels: ["twinLab Model", "Number of Samples", "Sampling Strategy"],
    sourceLabels: ["Samples"],
    params: {},
  },
};
